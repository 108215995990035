<template>
  <div>
    <div class="form-group form-group-sm">
      <label class="checkbox-inline">
        <input
          type="checkbox"
          data-testid="restore"
          v-model="formData.restoreButton"
        />
        <span>{{ $t("restore") }}</span>
      </label>
      <label class="checkbox-inline">
        <input
          type="checkbox"
          data-testid="save"
          v-model="formData.saveButton"
        />
        <span>{{ $t("save") }}</span>
      </label>
    </div>
    <div
      class="form-group form-group-sm"
      v-if="formData.restoreButton || formData.saveButton"
      style="margin-top:-15px;"
    >
      <div class="input-group">
        <div class="input-group-addon">
          {{ $t("visibility") }}
        </div>
        <select
          v-model="formData.visibility"
          id=""
          class="form-control no-padding"
        >
          <option
            :value="option.id"
            v-for="option in options"
            :key="option.id"
            >{{ $t(option.title) }}</option
          >
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import { isEqual } from "lodash";
export default {
  name: "DataValueInputToolbar",
  props: {
    value: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data: () => ({
    options: [
      { id: "always", title: "always" },
      { id: "focus", title: "synoptic.focus_state" }
    ],
    formData: {
      visibility: "never",
      restoreButton: false,
      saveButton: false
    }
  }),
  computed: {},
  watch: {
    value: {
      handler(n) {
        if (!n || isEqual(n, this.formData)) return;
        this.$set(this, "formData", JSON.parse(JSON.stringify(n)));
      },
      deep: true,
      immediate: true
    },
    formData: {
      handler(n) {
        if (!n) return;
        if (!n.restoreButton && !n.saveButton && n.visibility !== "never") {
          this.$nextTick(() => {
            this.formData.visibility = "never";
          });
          return; // important to avoid infinity loop
        }
        if ((n.restoreButton || n.saveButton) && n.visibility === "never") {
          this.$nextTick(() => {
            this.formData.visibility = "always";
          });
          return; // important to avoid infinity loop
        }
        if (isEqual(n, this.value)) return;
        this.$emit("input", n);
      },
      deep: true
    }
  }
};
</script>

<style scoped></style>
