<template>
  <div class="property-wrapper">
    <template v-if="controls.length == 1 && control.synopticComponent">
      <ControlBaseProperties
        v-model="control"
        @setExpression="buildExpression($event)"
        :hasExpression="expression != ''"
        :allowedTypes="['bool', 'float', 'int', 'string']"
      />

      <ControlSpecificPanel :control="control">
        <div class="form-group form-group-sm">
          <label for="">{{ $t("titles.form") }}</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              v-model="formName"
              ref="formName"
            />
            <div
              class="input-group-addon btn"
              @click.stop.prevent="
                formName = '';
                $refs.formName.focus();
              "
            >
              <i class="fa fa-close"></i>
            </div>
          </div>
        </div>
        <!-- data input does has fixed expression targeting the data id and can not be edited -->
        <div class="form-group form-group-sm">
          <label for="">{{ $t("expression") }}</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              :value="expression"
              disabled
              style="background-color: whitesmoke"
            />
            <div
              class="input-group-addon btn"
              @click.stop.prevent="expression = ''"
            >
              <i class="fa fa-close"></i>
            </div>
          </div>
        </div>
        <DataFormatInput
          :value="control.format"
          @input="updateFormat($event)"
          :control="control"
        />

        <label>
          {{ $t("controls") }} / {{ $tc("button", 2) }} / {{ $t("actions") }}
          <!-- {{ isLocal ? ` / ${$t("actions")}` : "" }} -->
        </label>
        <DataValueInputToolbar v-model="inputToolBar" />
        <div class="form-group">
          <label class="checkbox-inline">
            <input type="checkbox" data-testid="restore" v-model="saveOnTab" />
            <span>{{ $t("titles.save_on_tab") }}</span>
            <ToolTip :title="$t('hints.save_on_tab')" />
          </label>
        </div>
        <!-- <div class="form-group" v-if="isLocal"> -->
        <div class="form-group">
          <label class="checkbox-inline">
            <input
              type="checkbox"
              data-testid="restore"
              v-model="realTimeUpdate"
            />
            <span>{{ $t("titles.quick_preview") }}</span>
            <ToolTip :title="$t('hints.quick_preview')" />
          </label>
        </div>
        <DataValueCommandStatus :control="control" />
        <TogglePanel :title="$t('control_status')">
          <MultiColorsSelector
            class="multi-color-selector"
            v-model="errorStyle"
            :label="$t('synoptic.error_state')"
          />
          <MultiColorsSelector
            class="multi-color-selector"
            v-model="focusStyle"
            :label="$t('synoptic.focus_state')"
          />
          <MultiColorsSelector
            class="multi-color-selector"
            v-model="pendingStyle"
            :label="$t('synoptic.pending_state')"
          />
        </TogglePanel>
        <TogglePanel
          title="limits"
          class="toggle-panel"
          :collapsed="true"
          style="margin-top: 10px"
          v-if="dataType != 'string'"
        >
          <MinMaxForm
            v-model="minmax"
            ref="maxmin"
            :typeList="['none', 'constant', 'data']"
          />
          <div class="form-group form-group-sm" v-if="dataType != 'bool'">
            <label for="">{{ $t("increment") }}</label>
            <div class="input-group">
              <input
                type="number"
                class="form-control text-center"
                v-model="inputStep"
                ref="inputStep"
              />
              <div
                class="input-group-addon btn"
                @click.stop.prevent="
                  inputStep = 1;
                  $refs.inputStep.focus();
                "
              >
                <i class="fa fa-close"></i>
              </div>
            </div>
          </div>
        </TogglePanel>
      </ControlSpecificPanel>
    </template>
  </div>
</template>

<script>
import {isEqual, debounce} from "lodash";
import ControlBaseProperties from "./control-base-properties";
import BaseControl from "./base-control.vue";
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import DataValueCommandStatus from "./data-value-command-status.vue";
import ControlSpecificPanel from "@/components/editor/control-specific-panel.vue";
import MultiColorsSelector from "@/components/editor/multi-colors-selector";
import DataFormatInput from "@/components/synoptic/property-editor/controls/data-format-input.vue";
import DataValueInputToolbar from "@/components/synoptic/property-editor/controls/data-value-input-toolbar.vue";
import MinMaxForm from "@/components/synoptic/property-editor/controls/minmax-form.vue";
import ToolTip from "@/components/tooltip.vue";

export default {
  name: "DataValueInput",
  extends: BaseControl,
  components: {
    ControlBaseProperties,
    TogglePanel,
    DataValueCommandStatus,
    ControlSpecificPanel,
    MultiColorsSelector,
    DataFormatInput,
    DataValueInputToolbar,
    MinMaxForm,
    ToolTip
  },
  data() {
    return {
      control: {}
    };
  },
  computed: {
    dataId() {
      return this?.control?.data_id || "";
    },
    minmax: {
      set(value) {
        this.$set(this.control.synopticComponent, "min", value.min);
        this.$set(this.control.synopticComponent, "max", value.max);
      },
      get() {
        return {
          min: this.control.synopticComponent.min,
          max: this.control.synopticComponent.max
        };
      }
    },
    dataListParser() {
      return function (list) {
        return list.filter((item) => {
          return !item.read_only;
        });
      };
    },
    equipmentData() {
      if (this.dataId) {
        return (this.$store.getters["dashboard/dataList"] || []).find(
          ({id}) => id == this.dataId
        );
      }
      return null;
    },
    dataType() {
      return this?.equipmentData?.type || "";
    },
    isLocal() {
      return this?.equipmentData?.is_local ?? false;
    },
    saveOnTab: {
      set(value) {
        this.$set(this.control.synopticComponent, "saveOnTab", value);
      },
      get() {
        if (!("saveOnTab" in this?.control?.synopticComponent)) {
          return true; // legacy does not have this attribute
        }
        return this?.control?.synopticComponent?.saveOnTab;
      }
    },
    realTimeUpdate: {
      set(value) {
        this.$set(this.control.synopticComponent, "realTimeUpdate", value);
      },
      get() {
        if (!("realTimeUpdate" in this?.control?.synopticComponent)) {
          return false; // legacy does not have this attribute
        }
        return this?.control?.synopticComponent?.realTimeUpdate;
      }
    },
    inputToolBar: {
      set(value) {
        let widget = this?.control?.synopticComponent || {};
        this.$set(widget, "restoreButton", value.restoreButton);
        this.$set(widget, "saveButton", value.saveButton);
        this.$set(widget, "toolbarVisibility", value.visibility);
      },
      get() {
        let widget = this?.control?.synopticComponent || {};
        return {
          restoreButton: widget?.restoreButton || false,
          saveButton: widget?.saveButton || false,
          visibility:
            widget?.toolbarVisibility !== undefined
              ? widget.toolbarVisibility
              : widget?.restoreButton || widget?.saveButton // never set before
              ? "always"
              : "never"
        };
      }
    },
    errorStyle: {
      set(value) {
        this.__prop("errorStyle", value);
      },
      get() {
        return this.__prop("errorStyle");
      }
    },
    focusStyle: {
      set(value) {
        this.__prop("focusStyle", value);
      },
      get() {
        return this.__prop("focusStyle");
      }
    },
    pendingStyle: {
      set(value) {
        this.__prop("pendingStyle", value);
      },
      get() {
        return this.__prop("pendingStyle");
      }
    },
    expression: {
      set(value) {
        if (this?.control?.synopticComponent) {
          this.$set(this.control.synopticComponent, "expression", value);
        }
      },
      get() {
        return this?.control?.synopticComponent?.expression || "";
      }
    },
    formName: {
      set(value) {
        if (this?.control?.synopticComponent) {
          this.$set(this.control.synopticComponent, "formName", value);
        }
      },
      get() {
        return this?.control?.synopticComponent?.formName ?? "";
      }
    },
    inputStep: {
      set(value) {
        if (this?.control?.synopticComponent) {
          let vlr = parseInt(value);
          let max = this?.control?.synopticComponent?.max?.value;
          if (max !== undefined && parseInt(max) > 0) {
            vlr = vlr > parseInt(max) ? parseInt(max) : vlr;
          }
          this.$set(
            this.control.synopticComponent,
            "inputStep",
            isNaN(vlr) || vlr < 1 ? 1 : vlr
          );
        }
      },
      get() {
        let vlr = parseInt(this?.control?.synopticComponent?.inputStep ?? 1);
        return isNaN(vlr) || vlr < 1 ? 1 : vlr;
      }
    }
  },
  watch: {
    dataId(n) {
      if (!n) return;
      if (this.$refs.maxmin && this.equipmentData) {
        this.$refs.maxmin.updateFromData(this.equipmentData);
      }
    },
    control: {
      deep: true,
      handler(n) {
        this.delayedUpdate();
      }
    },
    controls: {
      deep: true,
      handler(val) {
        let n = JSON.parse(JSON.stringify(val[0]));
        let o = JSON.parse(JSON.stringify(this.control));
        if (!isEqual(n, o)) {
          this.control = JSON.parse(JSON.stringify(n));
        }
      }
    }
  },
  methods: {
    __prop(attr, value) {
      if ((attr && attr in this?.control?.synopticComponent) || {}) {
        if (value !== undefined) {
          this.$set(this.control.synopticComponent, attr, value);
        }
      }
      return (this?.control?.synopticComponent || {})[attr];
    },
    updateFormat(value) {
      this.control.format = value;
    },
    extendedDataListParser(lst) {
      return (lst || []).filter((item) => item?.device?.id >= 0);
    },
    updateControl() {
      this.$store.dispatch("synoptic/updateControl", {
        id: this.control.id,
        control: this.control,
        noMerge: true
      });
    },
    buildExpression(source) {
      this.control.format = "";
      this.expression = source
        .replace(/\$\('[\d]+/, "$('.")
        .replace(/\?\.name/, ".id");
      this.$utils.notifyUser(this, this.$t("titles.expression_created"));
    }
  },
  mounted() {
    this.control = JSON.parse(JSON.stringify(this.controls[0]));
  },
  created() {
    this.delayedUpdate = debounce(() => {
      this.updateControl();
    }, 500);
  }
};
</script>

<style scoped>
.toggle-panel {
  margin-bottom: 0;
}
.limit-box {
  margin: 0 0 4px 0;
}
.limit-box .input-group-addon {
  min-width: 80px;
}
.multi-color-selector {
  margin-bottom: 0.5em;
}
</style>
